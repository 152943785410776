<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Cấu hình cảnh báo rủi ro</h6>
        </template>
        <b-row>
          <b-col>
            <b-form-group label="Tên cấu hình">
              <b-form-input v-model="filter.name"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Trạng thái hoạt động">
              <b-form-select
                :options="status"
                v-model="filter.status"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col class="pt-4">
            <b-button class="mt-5" variant="outline-primary" @click="getList(1)"
            >Tìm kiếm
            </b-button>
          </b-col>
        </b-row>
        <hr />
        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
          <template #cell(index)="data">
            {{ (currentPage - 1) * 25 + (data.index + 1) }}
          </template>
          <template #cell(params)="data">
            <span v-bind:key="item" v-for="(item, index) in data.item.params">
              - {{ transformLabel(index) }}: {{ !isNaN(item) ? numberFormat(item) : item }} <br>
            </span>
          </template>

          <template #cell(time_frame)="data">
            {{ numberFormat(data.item.time_frame) }}
          </template>

          <template #cell(status)="data">
            <a href="javascript:void(0)" class="text-decoration-underline" @click="changeStatus(data.item)">
              <span v-if="data.item.status === 'ACTIVATE'" class="text-primary">{{ transformStatus(data.item.status) }}</span>
              <span v-else class="text-danger">{{ transformStatus(data.item.status) }}</span>
              <i class="fa fa-pencil"></i>
            </a>
          </template>
          <template #cell(action)="data">
            <button class="text-primary" @click="updateNotify(data.item)">
              <i class="fa fa-edit text-primary"></i>
            </button>
          </template>
        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>

    <b-modal id="my-modal" hide-footer ref="myModal" v-if="item !== null">
      <b-form>
        <b-row>
          <b-col>
            <b-form-group label="Tên cấu hình (*)">
              <b-form-input v-model="item.name"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Thời gian quét (phút) (*)">
              <b-form-input type="number" v-model="item.time_frame"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Nội dung cảnh báo (*)">
              <b-form-textarea rows="5" v-model="item.message"></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-bind:key="value" v-for="(value, key) in item.params">
          <b-col v-if="key === 'bank_code'">
            <b-form-group label="Danh sách ngân hàng áp dụng">
              <multiselect
                  v-if="object"
                  v-model="item.params.bank_code"
                  :options="object"
                  :multiple="true"
                  :taggable="true"
                  label="text"
                  track-by="text"
              ></multiselect>
            </b-form-group>
          </b-col>

          <b-col v-else>
            <b-form-group :label="transformLabel(key) + '(*)'">
              <b-form-input v-if="!isNaN(value)"
                :value="value"
                required
                @keydown="numbersOnly($event)"
                @change="setItemForUpdate(key, $event)"
              ></b-form-input>

              <b-form-input v-else
                :value="value"
                required
                @change="setItemForUpdate(key, $event)"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Trạng thái hoạt động (*)">
              <b-form-select
                :options="statusUpdate"
                v-model="item.status"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-button
                class="mr-3"
                variant="outline-primary"
                @click="handleUpdate()"
            >Cập nhật</b-button
            >
            <b-button variant="outline-danger" @click="cancelUpdate()">Huỷ</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import Multiselect from "vue-multiselect";

const RiskRepository = RepositoryFactory.get("risk");
const CmsRepository = RepositoryFactory.get("cms");
Vue.component("multiselect", Multiselect)
Vue.use(Notifications);
export default {
  mixins: [Common],
  components: {},
  data() {
    return {
      items: [],
      item: {
        name: null,
        time_frame: null,
        message: null,
        status: null,
        params: {},
      },
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      filter: {
        name: null,
        status: null,
      },
      fields: [
        {
          key: "index",
          label: "STT",
        },
        {
          key: "id",
          label: "ID",
        },
        {
          key: "name",
          label: "Tên cấu hình",
        },
        {
          key: "time_frame",
          label: "Thời gian quét (phút)",
        },
        {
          key: "params",
          label: "Thông số",
        },
        {
          key: "status",
          label: "Trạng thái hoạt động",
        },
        {
          key: "action",
          label: "Hành động",
        },
      ],
      status: [
        { text: "Tất cả", value: "" },
        { text: "Hoạt động", value: "ACTIVATE" },
        { text: "Không hoạt động", value: "DEACTIVATE" },
      ],
      statusUpdate: [
        { text: "Hoạt động", value: "ACTIVATE" },
        { text: "Không hoạt động", value: "DEACTIVATE" },
      ],
      object: [],
      banksProvider: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Cấu hình cảnh báo rủi ro", route: "tools/risk/notification" },
    ]);
  },
  methods: {
    convert(params) {
      for (let key in params) {
        if (params[key] == "") {
          delete params[key];
        }
      }
      return params;
    },
    getList(page = 1) {
      this.items = [];
      let params = { ...this.filter };
      params = this.convert(params);
      params.page = page;
      this.$bus.$emit("show-loading", true);
      RiskRepository.getListConfigNotify(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            return;
          }

          this.items = response.data.data.data;
          this.paginate.total = response.data.data.total;
          this.paginate.totalPage = response.data.data.last_page;
          this.paginate.currentPage = response.data.data.current_page;
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    updateNotify(item) {
      this.item.id = item.id;
      this.item.name = item.name;
      this.item.time_frame = item.time_frame;
      this.item.params = item.params;

      if (typeof item.params.bank_code !== "undefined") {
        let banks = item.params.bank_code;
        let banksData = [];
        let banksObject = this.banksProvider;

        banks.forEach(function(bank) {
          banksObject.forEach(function(item) {
            if (item.bank_code === bank) {
              banksData.push({ value: item.id, text: item.bank_code + ` - (${item.full_name})` });
            }
          });
        });

        this.item.params.bank_code = banksData;
      }

      this.item.status = item.status;
      this.item.message = item.message;
      this.$bvModal.show("my-modal");
    },
    setItemForUpdate(key, value) {
      this.item.params[key] = value;
    },
    handleUpdate() {
      if (!this.item.status) {
        this.notifyAlert("warn", "Không được để trống trạng thái");
        return false;
      }

      if (!this.item.time_frame) {
        this.notifyAlert("warn", "Không được để trống thời gian quét");
        return false;
      }

      if (!this.item.time_frame) {
        this.notifyAlert("warn", "Không được để trống thời gian quét");
        return false;
      }

      if (!this.item.message) {
        this.notifyAlert("warn", "Nội dung thông báo không được để trống");
        return false;
      }

      if (!this.item.params) {
        this.notifyAlert("warn", "Thống số không được để trống");
        return false;
      }

      let data = this.item.params;
      let checkParams = true;
      let message = '';
      let self = this;
      Object.entries(data).map(function([key, value]) {
        if (!value) {
          checkParams = false;
          message = self.transformLabel(key) + " không được để trống";
        }
      });

      if (!checkParams) {
        this.notifyAlert("warn", message);
        return false;
      }

      let params = { ...this.item };
      delete params.id;
      params = this.convert(params);

      if (typeof params.params.bank_code !== "undefined") {
        params.params.bank_code = JSON.stringify(params.params.bank_code);
      }

      this.$bus.$emit("show-loading", true);
      RiskRepository.updateConfigNotify(this.item.id, params)
        .then((response) => {
          this.$bvModal.hide("my-modal");

          if (response.data.error_code) {
            this.$bus.$emit("show-loading", false);
            this.notifyAlert("warn", response.data.message);
            return;
          }

          this.notifyAlert("success", response.data.message);
          this.$bus.$emit("show-loading", false);
          this.getList();
        })
        .catch(() => {
          this.$bvModal.hide("my-modal");
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
          this.getList();
        });
    },
    cancelUpdate() {
      this.$bvModal.hide("my-modal");
      this.getList();
    },
    changeStatus(item) {
      let config = item;
      let id = config.id;
      let cfm = confirm("Bạn có chăc muốn đổi trạng thái hoạt động không?");

      if (!cfm) {
        return true;
      }

      delete config.execute_class;
      delete config.execute_method;
      delete config.type;
      delete config.created_at;
      delete config.updated_at;
      config.status = config.status === "ACTIVATE" ? "DEACTIVATE" : "ACTIVATE";
      let params = config;
      params = this.convert(params);
      this.$bus.$emit("show-loading", true);
      RiskRepository.updateConfigNotify(id, params)
          .then((response) => {
            if (response.data.error_code) {
              this.$bus.$emit("show-loading", false);
              this.notifyAlert("warn", response.data.message);
              return;
            }

            this.notifyAlert("success", response.data.message);
            this.$bus.$emit("show-loading", false);
          })
          .catch(() => {
            this.$bvModal.hide("my-modal");
            this.$bus.$emit("show-loading", false);
            alert("Có lỗi xảy ra");
          });
    },
    transformStatus(status) {
      if (status === "ACTIVATE") {
        return "Hoạt động";
      }

      return "Không hoạt động";
    },
    transformLabel(key) {
      let labels = {
        account: "Số tài khoản",
        transaction: "Số giao dịch",
        amount_deposit: "Giá trị nạp",
        amount_transfer: "Giá trị chuyển",
        amount_withdraw: "Giá trị rút",
        amount_receive: "Giá trị nhận",
        device: "Thiết bị",
        start_time: "Thời gian bắt đầu",
        end_time: "Thời gian kết thúc",
        gmv: "GMV",
        bank_code: "Mã ngân hàng",
        card: "Số lượng thẻ",
      };

      return labels[key] !== undefined ? labels[key] : key;
    },
    async getBankProvider() {
      this.object = [];
      this.$bus.$emit("show-loading", true);

      let response = await CmsRepository.listBank(this.bank_params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;

      if (body.error_code > 0) {
        this.notifyAlert("warn", body.message);
        return true;
      }

      let banks = body.data.data;
      this.banksProvider = banks;
      let object = [];
      banks.forEach(function(item) {
        object.push({ value: item.id, text: item.bank_code + ` - (${item.full_name})` });
      });

      this.object = object;
    },
  },
  created() {
    this.query = this.$route.query;
    this.getList();
    this.getBankProvider();
  },
  watch: {
    currentPage() {
      this.getList(this.currentPage);
    },
  },
  filters: {
    capitalising: function (data) {
      let capitalized = [];
      data.split(" ").forEach((word) => {
        capitalized.push(
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
      });
      return capitalized.join(" ");
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
  .text-decoration-underline {
    text-decoration: underline !important;
  }
</style>
